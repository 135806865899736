@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.image-grid {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
}
.image-grid img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 12px;
    display: block;
    margin: 0 auto;
}
.contact-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-top: 5%;

}
.left-box {
    margin-bottom: 1rem;
}
.right-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 100%;
}
.contact-heading {
    margin-bottom: 1rem;
}
.contact-heading h2 {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    color: var(--nav-grey);
}
.contact-heading h3 {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    color: var(--nav-grey);
}
.contact-heading p{
    color: var(--nav-grey);
    font-size: 1rem;
}
.contact-hello {
    display: flex;
    flex-direction: column;
    color: var(--nav-grey);
}
.contact-hello p{
    color: var(--nav-grey);
    margin-bottom: 1rem;
    font-size: 1rem;
}
.hello-links {
    color: var(--rum-cont);
    margin: .5rem 0;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}
.hello-links:hover {
    color: var(--rum-cont);
}

.contact-Whats {
	text-decoration: none;
	color: var(--bege-cont);
  	font-size: 80%;
	font-weight: 500;
	letter-spacing: 3px;
	padding: 1rem 2rem;
	border: 1px solid var(--golden-cont);
	background-color: var(--rum-cont);
	width: fit-content;
	border-radius: 16px;
	overflow: hidden;
	position: relative;
	transition: 0.4s transform ease-in-out;
	will-change: transform;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
    font-family: 'Courier New', Courier, monospace;
}
.contact-Whats::after {
	background-color: var(--light-blue);
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	transform: translate(-100%, 0);
	transform-origin: top right;
	transition: 0.8s transform ease-out;
	will-change: transform;
	z-index: -1;
}
.contact-Whats:hover::after {
	transform: translate(0, 0);
}
.contact-Whats:hover {
	color: var(--golden-cont);
	border: 1px solid var(--golden-cont);
	will-change: transform;
}
.modal-open {
    overflow: hidden;
    height: 100%;
}
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
}
.modal-content {
    background-color: var(--nav-grey);
    padding: 20px;
    border-radius: 26px;
    width: 600px;
    height: 400px;
}
.modal-content h2 {
    color: var(--brownie-bgd);
    font-weight: 500;
    font-size: 1.2rem;
    font-family: 'Fields Display';
    src: url('../fonts/Fields_Display_Regular.otf') format('opentype');
    margin-top: 2rem;
    margin-bottom: 6rem;
    text-align: center;
}
.modal-content p {
    color: var(--bege-cont);
    font-weight: 400;
    font-size: 0.9rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    z-index: 999;
}
.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
.top-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
.img__cover {
    height: 200px;
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 1.4rem;
}
.contact-pic-small {
    display: flex;
    margin-top: 6rem;
    height: 200px;
    width: auto;
    border: none;
    transform: scale(1.4);
    z-index: 9;
}
.contact-socials-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.contact-socials-heading h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: var(--nav-grey);
    margin: 0;
}
.contact-socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 5rem;
}

.contact-socials svg {
    height: 40px;
    width: 40px;
}

@media screen and (max-width: 900px) {
    .contact-box {
        flex-direction: column;
        text-align: center;
    }
    .image-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 550px) {
    .contact-box {
      margin-top: 5rem;
    }
    .contact-heading h2{
        font-size: 1rem;
    }
    .contact-heading h3{
        margin-bottom: -1rem;
        font-size: 0.8rem;
    }
    .contact-heading p,.contact-hello p,.hello-links, input, textarea {
      font-size: 0.8rem;
    }
    .left-box {
        margin-bottom: 0;
    }
    .right-box {
        justify-content: space-evenly;
        gap: 1rem;
        margin-top: -4rem;
    }
    .image-grid {
        display: flex;
        justify-content: start;
        width: 100%;
        overflow-x: scroll;
        max-height: 400px;
        gap: 1rem;
    }
    .image-grid img {
        max-height: 250px;
        object-fit: cover;
        border-radius: 12px;
        display: block;
        margin: 0 auto;
        scroll-snap-align: start;
    }
    .modal-content {
        max-width: 360px;
        border-radius: 22px;
    }
    .contact-pic-small {
      height: 142px;
      width: auto;
    }
    .contact-socials {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 5rem;
    }
    .contact-socials-heading {
        margin-top: 7rem;
        margin-bottom: -5rem;
    }
}