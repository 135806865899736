@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.heading {
    margin-top: 1rem;
    margin-bottom: 2rem; 
    font-weight: 400;
    font-size: 2.2rem;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
}
.heading-sub-text {
    color: var(--nav-grey);
    font-weight: 400;
    font-family: 'Fields Display', sans-serif;
}

.heading-text {
    color: var(--nav-grey);
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8rem;
    font-weight: 400;

}

.upper-text-about {
    text-align: justify;
    margin-bottom: 2rem;
}
.about-content {
    font-family: "Raleway", sans-serif;
    line-height: 1.2;
    font-size: 0.8rem;
    margin-top: 1rem;
}
.about-text {
    color: var(--nav-grey);
    font-size: 1.5rem;
}
.about-subText {
    color: var(--nav-grey);
    font-size:0.9rem;
    font-weight: 600;
}
.about-text2 {
    color: var(--nav-grey);
    font-size: 1.5rem;
    font-family: 'Fields Bold';
    src: url('../fonts/Fields_Bold.otf') format('opentype');
    font-weight: 600;
    margin-top: 1rem;
}
.about-subText2 {
    color: var(--nav-grey);
    font-size:0.9rem;
    font-weight: 600;
    margin-top: 1rem;
}
.about-img {
    width: 100%;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    justify-content: center;
    position: relative;
    z-index: 1;
}
.about-img img {
    width: auto;
    height: 350px;
    border-radius: 25px;
    filter: brightness(100%);
    cursor: pointer;
}
@media screen and (max-width: 1024px) {
    .split-about {
        justify-content: center;
        align-items: center;
    }
    .about-content {
        margin-top: 1rem;
    }
}
@media screen and (max-width: 1000px) {
    .split-about {
        justify-content: center;
        align-items: center;
    }
    .about-content {
        margin-top: 2rem;
    }
}
@media screen and (max-width: 500px) {
    .split-about {
        margin-bottom: 0;
    }
    .about-img img {
        width: auto;
        height: 160px;
    }
    .heading {
        text-align: center;
        justify-content: center;
        margin-bottom: 1rem;
    }
    .heading-text {
        font-size: 1.2rem;
    }
    .heading-sub-text {
        font-size: 90%;
    }
    .upper-text-about {
        margin-bottom: 1rem;
    }
    .about-text {
        font-size: 0.8rem;
    }
    .about-subText {
        font-size: 0.7rem;
    }
    .about-text2 {
        text-align: center;
        color: var(--nav-grey);
        font-size: 1.3rem;
        font-family: 'Fields Display';
        src: url('../fonts/Fields_Display_Regular.otf') format('opentype');
        margin-top: 0;
    }
    .about-subText2 {
        text-align: justify;
        font-size: 0.6rem;
    }
    .about-content {
        font-size: 0.6rem;
    }
}