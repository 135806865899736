@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Open+Sans:wght@400;500&family=Poppins:wght@300;400;600&family=Quicksand:wght@400;600&family=Roboto+Condensed:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'Fields Display';
  src: url('../fonts/Fields_Display_Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  
}
html {
  scroll-behavior: smooth;
  background-color: var(--nav-grey);
}
body::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}
body::-webkit-scrollbar-track {
  background: var(--brownie-bgd);
}
body::-webkit-scrollbar-thumb {
  background: var(--light-blue);
  border-radius: 100vh;
}
body::-webkit-scrollbar-thumb:hover {
  background: var(--bege-cont);
  cursor: grab;
}
body.modal-open {
  overflow: hidden;
}
html, body {
  overflow-x: hidden;
}
:root {
  --nav-grey: #194d59;
  --nav-deep-grey: #94959b;
  --nav-orange: #E97451;
  --nav-pink-shade: #9d3d71;
  --card-peach-shade: #FFA07A;
  --nav-dark-samon: #e1bd8d;
  --nav-bgd: #040b14;
  --white: #fff;
  --rust: #D2691E;
  --navy: #160c2c;
  --light-blue: #194d59;
  --lighter-blue: #356367;
  --section-bgd: #8B3A3A;
  --brownie-bgd: #8e837d;
  --bege-cont: #d2d3bb;
  --golden-cont: #e1bd8d;
  --rum-cont: #DEADA1;

}
/* General styles */
.about,
.contact {
  background-color: var(--bege-cont);
  color: var(--nav-grey);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.custom-heading {
  font-family: 'Fields Display', sans-serif;
  font-size: 24px;
  color: var(--nav-grey);
}
.AyraPilates {
  background-color: var(--nav-grey);
  color: var(--golden-cont);
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}
.AyraFisio {
  position: relative;
  background-color: var(--bege-cont);
  color: var(--nav-grey);
  width: 100vw;
  height: 100%;
  overflow-y: hidden;
}

.AyraFisio::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Slight darkening with 20% opacity */
  pointer-events: none; /* Allows interactions through the overlay */
  z-index: 1; /* Ensure it's above the background but below the content */
}
.AyraWomenH {
  background-color: var(--nav-grey);
  color: var(--bege-cont);
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}
.AyraEduc {
  color: var(--bege-cont);
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}
.container {
  padding: 4rem;
  max-width: 100%;
  margin: 0 auto;
  color: var(--bege-cont);
}
.main {
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  max-width: 100vw;
  scroll-behavior: smooth;
  position: relative;
  opacity: 1;
}
.main > div {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease-in-out;
  opacity: 1;
}
.loader {
  background-color: var(--light-blue);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.loader-icon {
  color: var(--rum-cont);
  height: 100;
  width: 50;
}
.svg-wrapper {
  position: relative;
}
@keyframes pageLoad {
  0% {
    stroke-dasharray: 140 540;
    stroke-dashoffset: -474;
    stroke-width: 8px;
  }
  100% {
    stroke-width: 2px;
    stroke-dashoffset: 0;
    stroke-dasharray: 760;
  }
}
.shape {
  stroke-dasharray: 140 540;
  stroke-dashoffset: -474;
  stroke-width: 8px;
  fill: transparent;
  stroke: var(--lighter-blue);
  border: 5px solid black;
  animation: 4s ease 0s 1 pageLoad;
}
.text {
  font-size: 2rem;
  font-family: 'Fields Display', sans-serif;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 32px;
  color: var(--bege-cont);
  position: absolute;
  top: 10px;
  width: 100%;
  transform: translate(-50% -50%);
}
@media screen and (max-width: 700px) {
  .container {
    padding: 3rem;
  }
  .text {
    font-size: 14px;
    letter-spacing: 7px;
    margin-left:12px;
  }
}
@media screen and (max-width: 500px) {
  .AyraPilates, .about, .AyraWomenH {
    height: auto;
    min-height: 100vh;
  }
  .AyraFisio, .AyraEduc {
    height: 100vh;
  }
  .text {
    font-size: 2rem;
    font-family: 'Fields Display', sans-serif;
    letter-spacing: 0.5px;
    text-align: center;
    justify-content: center;
    line-height: 32px;
    color: var(--bege-cont);
    position: absolute;
    top: 10px;
    width: 100%;
    transform: translate(-50% -50%);
  }
  .container {
    padding: 1rem;
  }
}
