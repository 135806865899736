.services-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
  height: 80vh;
  transition-delay: 2s;
}
.education-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 6rem;
  margin-top: 5vh;
  height: 30vh;
}
.pilates-img {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  gap: 1rem;
}
.pilates-img img {
  height: 300px;
  width: auto;
  z-index: 1;
  border-radius: 23px;
  animation-delay: 2s;
  box-shadow: 3px 3px 1px #0202022a;
}
.pilates-header {
  margin-bottom: 1rem;
  color: var(--brownie-bgd);
}
.pilates-banner {
  display: flex;
  justify-content: left;
  align-items: start;
  position: relative;
  z-index: 1;
}
.pilates-bannerimg {
  max-width: 100%;
  max-height: 200px;
  display: flex;
  margin-top: 1rem;
  justify-content: left;
  align-items: start;
}
.pilates-bannerimg img {
  width: auto;
  height: 80px;
  object-fit: cover;
}
.pilates-text {
    text-align: justify;
    text-justify: inter-word;
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--bege-cont);
}
.pilates-text-bright {
    text-align: justify;
    text-justify: inter-word;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--nav-grey);
}
.services-box a {
    text-decoration: none;
    color: #fff;
}
.close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}
.close-btn:hover {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
.educ-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.heading-sub-text-educ {
    color: var(--nav-dark-samon);
    font-weight: 400;
    font-family: 'Fields Display', sans-serif;
    font-size: 2rem;
}
.heading-text-educ {
    color: var(--nav-dark-samon);
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8rem;
    font-weight: 400;
    margin-top: 3vh;
}
.educ-image {
    width: 50%;
    height: auto;
    object-fit: contain;
    
}
@media screen and (max-width: 1024px) {
    .services-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }

    .pilates-img img {
        max-height: 200px;
        width: auto;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }
    .pilates-img-two img {
        height: 200px;
        width: auto;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
    }
    .pilates-text {
        text-align: justify;
        text-justify: inter-word;
        font-size: 0.8rem;
        font-weight: 400;
        color: var(--bege-cont);
    }
    .pilates-text-bright {
        text-align: justify;
        text-justify: inter-word;
        font-size: 0.8rem;
        font-weight: 400;
    }
}
@media screen and (max-width: 768px) {
    .services-box {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 3rem;
    }
    .pilates-img img {
        max-height: 300px;
        max-width: auto;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        border-radius: 25px;
    }
    .pilates-img img:nth-child(2) {
        display: none;
    }
    .pilates-text {
        font-size: 0.8rem;
        font-weight: 400;
    }
    .pilates-text-bright {
        font-size: 0.8rem;
        font-weight: 400;
    }
    .pilates-img-two img {
        max-height: 150px;
        max-width: auto;
        display: flex;
        justify-content: center;
        border-radius: 25px;
    }   
}
@media screen and (max-width: 550px) {
    .services-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        margin-top: 4rem;
        height: fit-content;
    }
    .pilates-bannerimg {
        max-width: auto;
        max-height: 220px;
        display: flex;
        margin-left: 3rem;
        justify-content: left;
        align-items: start;
    }
    .pilates-bannerimg img {
        width: auto;
        height: 40px;
        object-fit: cover;
    }
    .pilates-img img {
        max-height: 220px;
        min-width: 400px;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        border-radius: 25px;
    }
    .pilates-text {
        font-size: 0.9rem;
        font-weight: 400;
        margin-top: 2rem;
    }
    .pilates-text-bright {
        font-size: 0.9rem;
        font-weight: 400;
        margin-top: 2rem;
    }
}
@media screen and (max-width: 375px) {
    .services-box {

        margin-top: 2rem;
        height: fit-content;
    }
    .pilates-text {
        font-size: 0.6rem;
        font-weight: 600;
    }
    .pilates-text-bright {
        font-size: 0.6rem;
        font-weight: 600;
    }
    .pilates-img img {
        max-height: auto;
        width: 98vw;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        border-radius: 25px;
    }
}

