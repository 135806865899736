.popup-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  overflow-y: auto;
  
}
.popup-content {
  background: var(--rum-cont);
  border-radius: 8px;
  padding: 20px;
  max-width: 700px;
  width: 96%;
  max-height: 90vh;
  overflow-y: auto;
  border-left: 2px solid rgb(253, 210, 210);
  border-top: 2px solid rgb(253, 210, 210);
}
/* Webkit-based browsers (Chrome, Safari, etc.) */
.popup-content::-webkit-scrollbar {
  width: 8px;
}
.popup-content::-webkit-scrollbar-thumb {
  background-color: var(--nav-grey);
  border-radius: 10px;
}
.popup-content::-webkit-scrollbar-track {
  background: transparent;
}
/* For Firefox */
.popup-content {
  scrollbar-width: thin;
  scrollbar-color: var(--nav-grey) transparent;
}
/* Hide scrollbar track but keep thumb visible */
.popup-content::-moz-scrollbar-track {
  background: transparent;
}
.image-popup-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.image-popup-content {
  background: white;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}
.enlarged-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.close-btn {
  position: absolute;
  font-size: 1.5rem;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 999999;
}
.close-btn svg {
  width: 32px;
  height: 32px;
  color: rgb(252, 252, 252);
}
.close-btn:hover svg {
  color: var(--nav-grey);
}
.popup-body {
  text-align: center;
}
.popup-welcome {
  text-align: center;
}
.popup-welcome h3{
  font-family: 'Fields Display';
  src: url('../fonts/Fields_Display_Regular.otf') format('opentype');
  
}
.top-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img_cover{
  height: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-pic-small-popUp {
  height: 70%;
  width: auto;
  border: none;
}
.image-popup-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.image-popup-content {
  position: relative; 
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-popup-content .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 99999;
}
/* Section 1: Google Maps and Location Info */
.location-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.map-container {
  flex: 1;
}
.map-container iframe {
border-radius: 16px;
height: 220px;
}
.location-info {
  flex: 1;
  padding-left: 2rem;
}
.location-info h2 {
  margin-bottom: 1rem;
  font-size: 1rem;
}
.location-info p {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 1.1rem;
}
.text-section {
  text-align: center;
  margin-bottom: 2rem;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.text-section h2 {
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-family: 'Fields Display';
  src: url('../fonts/Fields_Display_Regular.otf') format('opentype');
}
.images-section h2 {
  text-align: center;
  margin-top:2rem;
  font-size: 1.2rem;
  font-family: 'Fields Display';
  src: url('../fonts/Fields_Display_Regular.otf') format('opentype');
}
.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
}
.images-grid img {
  width: 260px;
  height: 260px;
  border-radius: 25px;
  object-fit: fill;
  cursor: pointer;
  transition: transform 0.4s ease;
}
@media (max-width: 768px) {
  .popup-content {
    width: 90%;
    height: 97vh;
    padding: 1.5rem;
    margin: 2rem;
  } 
  .close-btn {
    top: 8px; 
    right: 8px; 
  } 
  .location-section {
    flex-direction: column; 
    align-items: flex-start;
  }
  .location-info {
    padding-left: 0; 
    margin-top: 1rem; 
  }
  .map-container {
    flex: 1; 
  }
  .images-grid img {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 480px) {
  .popup-background {
    padding: 1rem;
    padding-top: 0;
  }
  .popup-content {
    width: 100%;
    max-height: 100vh;
    padding: 0.9rem;
    margin: 0;
    align-items: center;
    align-content: center;
    position: relative; /* Needed for the close button to stay fixed */
  }
  .popup-welcome p{
    font-size: 0.8rem;
    margin-top: 1rem;
    font-weight: 600;
  }
  .close-btn {
    top: 12px;
    right: 12px;
    position: absolute;
    z-index: 9999999;
  }
  .close-btn svg {
    width: 32px;
    height: 32px;
    color: rgb(0, 0, 0);
  }
  .popup-body {
    margin-top: 0;
    padding-top: 0;
  }
  .profile-pic-small-popUp {
    width: 100%;
    height: auto;
    border: none;
    display: block;
    margin: 0 auto;
  }
  .map-container {
    margin-top: 1rem;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .map-container iframe {
    max-width: 100%;
    height: 100%;
  }
  .images-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .images-grid img {
    width: 100px;
    height: auto;
    border-radius: 10px;
    cursor: pointer;
  }
  .text-section {
    margin-bottom: 1rem;
  }
  .location-section {
    flex-direction: column;
    align-items: center;
  }
  .location-info {
    padding-left: 0;
    text-align: center;
  }
  .images-section {
    text-align: center;
  }
}