@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.navbar {
    background-color: var(--brownie-bgd);
    color: var(--bege-cont);
    position: fixed;
    top: 0;
    left: -100%;
    bottom: 0;
    opacity: 0;
    overflow-y: scroll;
    transition: all .5s ease-in-out;
    z-index: 99998;
    border-right: 3px solid var(--light-blue);
}
.navbar.active {
    left: 0;
    opacity: 1;
}
.navbar::-webkit-scrollbar {
    scroll-margin: 0;
}
.navbar-container {
    padding: 1rem 1.5rem;
}
.top-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.img__cover{
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 1.4rem;
}
.profile-pic-small {
    height: 60%;
    width: auto;
    border: none;
    transform: scale(1.4);
}
.nav-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 0.8rem;
}
.nav-links li, .mid-details li {
    list-style: none;
}
.nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: var(--light-blue);
    margin: 0 .3rem;
    padding: .7rem;
    background-color: var(--bege-cont);
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
}
.nav-link:hover {
    background-color: var(--rum-cont);
    color: var(--bege-cont);
}
.mid-details {
    padding: 1rem;
}
.flex{
    display: flex;
}
.mid-links {
    display: flex;
    align-items: center;
    color: var(--light-blue);
    cursor: pointer;
    text-decoration: none;
    padding: 1rem 0;
    transition: all 0.3s ease-in-out;
}
.mid-links.active {
    color: var(--bege-cont);
}
.mid-links:hover {
    color: var(--rum-cont);
}
.mid-links-disabled {
    position: relative;
    color: var(--lighter-blue);
    display: flex;
    align-items: center;
    cursor: not-allowed;
    opacity: 1;
    margin-bottom: 1rem;
}
.coming-soon {
    font-family: 'Fields Display';
    src: url('../fonts/Fields_Display_Regular.otf') format('opentype');
    font-weight: 600;
    font-size: 0.7rem;
    color: var(--rum-cont);
    position: absolute;
    top: 0;
    right: 12px;
    font-style: italic;
}
.mid-link {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: italic;
    font-size: 1rem;
}
.mid-links:hover .mid-icon { color: var(--light-blue); }
.mid-icon {
    margin-right: 1rem;
    font-size: 1.5rem;
}
.socials {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 6rem;
}
.bottom-copy {
    display: flex;
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 100%;
    justify-content: center;
}
.copy {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 1%;
    margin-left: 1.1rem;
}
.copy a{
    color: var(--bege-cont);
    text-decoration: none;
}
.copyright {
    color: var(--bege-cont);
    font-size: 0.6rem;
    text-decoration: none;
}
@media screen and (max-width: 768px) {
    .mid-links {
        display: flex;
        align-items: center;
        color: var(--light-blue);
        cursor: pointer;
        text-decoration: none;
        padding: 0.8rem 0;
        transition: all 0.3s ease-in-out;
    }
    .mid-link {
        font-size: 0.9rem;
    }
}
@media screen and (max-width: 550px) {
    .top-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0rem;
    }
    .mid-links {
        display: flex;
        align-items: center;
        color: var(--light-blue);
        cursor: pointer;
        text-decoration: none;
        padding: 0.8rem 0;
        transition: all 0.3s ease-in-out;
    }
    .mid-link {
        font-size: 0.8rem;
    }
    .socials {
        margin-top: 3rem;
    }
    .copy {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .copyright {
        color: var(--bege-cont);
        font-size: 0.5rem;
        text-decoration: none;
    }
    .mid-icon {
        height: 20px;
        width: 20px;
    }
    .coming-soon {
        right: 2rem;
    }
}
