@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.hero-section {
	background-color: var(--light-blue);
	background-attachment: fixed;
	background-size: cover;
	width: 100%;
	height: 100vh;
	padding: 3rem 6rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	overflow-y: hidden;
}
.hero-overlay {
	position: absolute;
	height: 100vh;
	width: 100%;
}
.hero-image {
    width: 80%;
    height: auto;
    object-fit: contain;
}
.hero-content {
	margin-bottom: 2rem;
}
.hero-intro {
	font-size: 4rem;
	margin-bottom: 0.5rem;
	color: var(--brownie-bgd);
	display: flex;
	flex-direction: column;
}
.hero-desc,
.hero-desc-sub {
	font-size: 1.2rem;
	font-family: 'Fields Display', sans-serif;
	font-weight: 200;
}
.hero-desc-sub {
	color: var(--brownie-bgd);
	font-weight: 400;
}
.hero-contact {
	text-decoration: none;
	color: var(--bege-cont);
  	font-size: 80%;
	font-weight: 500;
	letter-spacing: 3px;
	padding: 1rem 2rem;
	border: 1px solid var(--bege-cont);
	background-color: transparent;
	width: fit-content;
	border-radius: 4px;
	overflow: hidden;
	position: relative;
	transition: 0.4s transform ease-in-out;
	will-change: transform;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}
.hero-contact::after {
	background-color: var(--lighter-blue);
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	transform: translate(-100%, 0);
	transform-origin: top right;
	transition: 0.8s transform ease-out;
	will-change: transform;
	z-index: -1;
}
.hero-contact:hover::after {
	transform: translate(0, 0);
}
.hero-contact:hover {
	color: var(--golden-cont);
	border: 1px solid var(--golden-cont);
	will-change: transform;
}
.hero-content .hero-desc {
	color: var(--bege-cont);
	overflow: hidden;
	line-height: 2;
	letter-spacing: 0.15em;
}
.menu-icon,
.to-top-icon {
	padding: 0.7rem;
	cursor: pointer;
	font-size: 1.3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	transition: all 0.4s ease;
	z-index: 99999998;
	color: var(--bege-cont);
}
.menu-icon {
	position: fixed;
	top: 1rem;
	left: 1rem;
}
.menu-icon:hover {
	color: var(--golden-cont);
}
.cv-icon {
	margin-left: 0.5rem;
}
.to-top-icon {
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	color: var(--bege-cont);
}
.to-top-icon:hover {
	background-color: var(--light-blue);
}
.show {
	visibility: visible;
	opacity: 1;
}
.hide {
	visibility: hidden;
	opacity: 0;
}
@media screen and (max-width: 768px) {
	.menu-icon,
	.to-top-icon {
		font-size: 1rem;
	}
}
@media screen and (max-width: 650px) {
	.hero-intro {
		font-size: 5rem;
	}
}
@media screen and (max-width: 500px) {
	.hero-section {
		padding: 3rem 1rem;
		white-space: normal;
	}
	.hero-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.hero-intro {
		font-size: 3rem;
	}
	.hero-desc,
	.hero-desc-sub {
		font-size: 1rem;
	}
	.hero-contact {
		font-size: 80%;
	}
	.menu-icon,
	.to-top-icon {
		font-size: 1rem;
		padding: 0.6rem;
		background-color: var(--brownie-bgd);
	}
}
